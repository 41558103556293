.srd-diagram {
  position: relative;
  flex-grow: 1;
  display: flex;
  overflow: hidden; }
  .srd-diagram__selector {
    position: absolute;
    background-color: rgba(35, 198, 252, 0.2);
    border: solid 2px #00c0ff; }

.srd-link-layer {
  position: absolute;
  height: 100%;
  width: 100%;
  transform-origin: 0 0;
  overflow: visible !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.srd-node-layer {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  pointer-events: none;
  transform-origin: 0 0;
  width: 100%;
  height: 100%; }

.srd-node {
  position: absolute;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  user-select: none;
  cursor: move;
  pointer-events: all; }

.srd-node--selected  {
    box-shadow:0 0 10px 5px #505152;
}

.srd-port {
  width: 20px;
  background-color: #505152;
  border-radius: 20px;
  border-style: solid;
  border-color: inherit;
  border-width: 5px;
  top :50px;
  height: 20px;
  position: absolute;
}
  
.srd-port:hover, .srd-port.selected {
    background: #c0ff00; }

.srd-default-node {
  background-color: #1e1e1e;
  border-radius: 3px;
  font-family: sans-serif;
  color: white;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  overflow: visible;
  font-size: 11px;
  height: 80px;
  width: 150px; }
  
  .srd-default-node__title {
    display: flex;
    white-space: nowrap; }
    .srd-default-node__title > * {
      align-self: center; }
    .srd-default-node__title .fa {
      padding: 5px;
      opacity: 0.2;
      cursor: pointer; }
      .srd-default-node__title .fa:hover {
        opacity: 1.0; }
  .srd-default-node__name {
    flex-grow: 1;
    padding: 5px 0px 0px 5px;
    overflow: hidden; }
  .srd-default-node__ports {
    display: flex;
  }
  
  .srd-default-node__tooltip_title {
    font-weight: bold;
    text-align: center;
  }

.srd-default-node__in, .srd-default-node__out {
    flex-grow: 1;
    display: flex;
    flex-direction: column; 
}
.srd-default-node__changed {
  position: relative;
  top:3px;
}

.srd-default-node__delayed {
  position: relative;
  align-self: center;
  top:3px;
}

.srd-default-node__content {
  position: relative;
  /* background-color: #c0ff00; */
  padding: 0 5px 0 5px;
  /* align-self: center; */
  text-align: center;
  font-size: 10pt;
  top:3px;
}

.srd-default-port {
  display: flex;
  margin-top: 1px; 
  border-color: inherit;
}

.srd-default-port > * {
    align-self: center; 
}
.srd-default-port--in > * {
  top:-10px;
  left:70px;
}
.srd-default-port--out > * {
  left:70px;
  top :calc(100% - 10px);
}

.srd-default-port__name {
    padding: 0 5px; }
  .srd-default-port--out {
  justify-content: flex-end; 
   }
  
   .srd-default-port--out .srd-default-port__name {
      justify-content: flex-end;
      text-align: right; }

.srd-default-port [id='nodeIcon'] {     
  font-size: 36px;
  position: relative;
  top: 10px;
}
 .srd-default-port--in [id='nodeIcon'] {
        /* background-color: aqua; */
        left: -4px;
      }
     
 .srd-default-port--out [id='nodeIcon'] {
   /* background-color: #c0ff00; */
   right: -4px;
 }


.srd-default-label {
  background: rgba(70, 70, 70, 0.8);
  border: 1px solid #333;
  border-radius: 4px;
  color: #fff;
  display: inline-block;
  font-size: smaller;
  padding: 5px; }

@keyframes dash {
  from {
    stroke-dashoffset: 24; }
  to {
    stroke-dashoffset: 0; } }

.srd-default-link path {
  stroke: #f26430 ;
  fill: none;
  stroke-width: 3px;
  pointer-events: all; }

.srd-default-link--path-selected {
  /* stroke: #f26430 !important; */
  stroke-dasharray: 10,2;
  /* animation: dash 3s linear infinite;  */
}

.srd-default-link--path-executed {
  stroke:rgba(00, 00, 200, 0.8) !important;
  stroke-dasharray: 23,1;
  animation: dash 2s linear infinite; 
}
  
.srd-default-link__label {
  pointer-events: none; }
  .srd-default-link__label > div {
    display: inline-block;
    position: absolute; }

.srd-default-link__point {
  fill: rgba(255, 255, 255, 0.5); }

.srd-default-link--point-selected {
  fill: #00c0ff; }
