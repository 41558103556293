@font-face {
    font-family: 'Lovelo';src: url('/Lovelo Line Light.otf');
}

.logo {
    font-family: Lovelo, Arial, sans-serif; font-size:2em;color: white;
}

.se-centered {
    display: flex;
    justify-content: center;
}

.se-section {
    padding: 10px;
}
.se-section-title {
    padding: 30px;
    font-weight: bold;
    font-size: 150%;
}
.react-monaco-editor-container {
    margin-bottom: 25px;

}
.overflow-guard {
    border-width: 1px;
    border-radius: 2px;
    border-style: solid;
    border-color: rgb(190,190,190);
    margin-bottom: 25px;

}

.testNow {
    flex-direction: row !important;
    /* background-color: aqua; */
    padding-left: 35%;
}

::-webkit-scrollbar {
    width: 5px;
    height : 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1; 
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555; 
}
