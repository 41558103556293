@import url(./style/main.css);

.diagram-layer{
  height:100%;
  /* width: 100%; */
	background-image:
    linear-gradient(rgba(0,99,203, 0.1) 2px, transparent 2px), 
    linear-gradient(90deg, rgba(0,99,203, 0.1) 2px, transparent 2px), 
    linear-gradient(rgba(0,99,203, 0.15) 1px, transparent 1px), 
    linear-gradient(90deg, rgba(0,99,203, 0.15) 1px, transparent 1px);
	background-size: 50px 50px, 50px 50px, 10px 10px, 10px 10px;
  background-position: -2px -2px, -2px -2px, -1px -1px, -1px -1px;  
  border-color: rgba(0,99,203, 0.2);
  border-style: solid;
  border-width: 2px;
}

.diagram-canvas {
    height: 100%;
  }
