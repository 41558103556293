
.menuItem {
    line-height:64px;
}
.menuActions {
    display: -webkit-inline-box;
    line-height:64px;
    margin-left: 40px;
}

.se-editable-cell-dropdown {
    min-width:50%;
    max-width:100%;

}

.se-admin-header {

    padding: 0px 0px 8px 30px;
    margin-top: 10px;
    border-width: 0px 0px 3px 0px ;
    border-style: solid;
    border-color: #1890ff;
    font-weight: bold;
    font-size: 22px;

}

.se-admin-table {
    margin: 10px 60px 0px 60px;
}
.se-admin-table-container{
    border-width:0px 0px 3px 0px;
    border-style: solid;
    border-color: #1890ff;

}

.se-admin-table-row>td {
    padding: 0px 0px 4px 0px !important;
 }


 .se-admin-table-add-button {
    position: relative;
    top: -50px;
    left: 25px;
    float: left;
    width :100px;
    z-index: 10;
    background-color: transparent;
    border-width: 1px;
    border-color: transparent;
    font-size: 14pt;
    color: #1890ff;
    
}
.se-admin-table-add-button:hover {
    color: #0055a1;
    background-color : #e6e6e6; 
    border-width: 1px;
    border-style: solid;
    border-radius: 2px;
}

/**
 */
#vg-tooltip-element > table > tbody > * > td.key {
     display: none;
}

#vg-tooltip-element > table > tbody > tr:nth-child(1) > td.value {
    color: #1890ff;
    font-size: 45px;
    font-weight: bold;
    position: absolute;
    width: 100%;
    text-align: center;
    left: 0px;
    top :15%;
    
}
#vg-tooltip-element > table > tbody > tr:nth-child(2) > td.value {
    color: #0055a1;
    font-size: 16px;
    font-weight: bold;
    position: absolute;
    width: 100%;
    text-align: center;
    left: 0px;
    top :65%;
}
 #vg-tooltip-element {
    color: red;
    padding : 30px;
    /* visibility: visible !important ; */
    width: 100px;
    height: 100px !important;
    border-radius: 50px;

    box-shadow: 5px 5px 4px gray;
    -webkit-transition: visibility 05s, opacity 0.5s linear;
    transition: visibility 05s, opacity 0.5s linear;
  
  }
.srd-diagram {
  position: relative;
  flex-grow: 1;
  display: flex;
  overflow: hidden; }
  .srd-diagram__selector {
    position: absolute;
    background-color: rgba(35, 198, 252, 0.2);
    border: solid 2px #00c0ff; }

.srd-link-layer {
  position: absolute;
  height: 100%;
  width: 100%;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  overflow: visible !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.srd-node-layer {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  pointer-events: none;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  width: 100%;
  height: 100%; }

.srd-node {
  position: absolute;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
  cursor: move;
  pointer-events: all; }

.srd-node--selected  {
    box-shadow:0 0 10px 5px #505152;
}

.srd-port {
  width: 20px;
  background-color: #505152;
  border-radius: 20px;
  border-style: solid;
  border-color: inherit;
  border-width: 5px;
  top :50px;
  height: 20px;
  position: absolute;
}
  
.srd-port:hover, .srd-port.selected {
    background: #c0ff00; }

.srd-default-node {
  background-color: #1e1e1e;
  border-radius: 3px;
  font-family: sans-serif;
  color: white;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  overflow: visible;
  font-size: 11px;
  height: 80px;
  width: 150px; }
  
  .srd-default-node__title {
    display: flex;
    white-space: nowrap; }
    .srd-default-node__title > * {
      align-self: center; }
    .srd-default-node__title .fa {
      padding: 5px;
      opacity: 0.2;
      cursor: pointer; }
      .srd-default-node__title .fa:hover {
        opacity: 1.0; }
  .srd-default-node__name {
    flex-grow: 1;
    padding: 5px 0px 0px 5px;
    overflow: hidden; }
  .srd-default-node__ports {
    display: flex;
  }
  
  .srd-default-node__tooltip_title {
    font-weight: bold;
    text-align: center;
  }

.srd-default-node__in, .srd-default-node__out {
    flex-grow: 1;
    display: flex;
    flex-direction: column; 
}
.srd-default-node__changed {
  position: relative;
  top:3px;
}

.srd-default-node__delayed {
  position: relative;
  align-self: center;
  top:3px;
}

.srd-default-node__content {
  position: relative;
  /* background-color: #c0ff00; */
  padding: 0 5px 0 5px;
  /* align-self: center; */
  text-align: center;
  font-size: 10pt;
  top:3px;
}

.srd-default-port {
  display: flex;
  margin-top: 1px; 
  border-color: inherit;
}

.srd-default-port > * {
    align-self: center; 
}
.srd-default-port--in > * {
  top:-10px;
  left:70px;
}
.srd-default-port--out > * {
  left:70px;
  top :calc(100% - 10px);
}

.srd-default-port__name {
    padding: 0 5px; }
  .srd-default-port--out {
  justify-content: flex-end; 
   }
  
   .srd-default-port--out .srd-default-port__name {
      justify-content: flex-end;
      text-align: right; }

.srd-default-port [id='nodeIcon'] {     
  font-size: 36px;
  position: relative;
  top: 10px;
}
 .srd-default-port--in [id='nodeIcon'] {
        /* background-color: aqua; */
        left: -4px;
      }
     
 .srd-default-port--out [id='nodeIcon'] {
   /* background-color: #c0ff00; */
   right: -4px;
 }


.srd-default-label {
  background: rgba(70, 70, 70, 0.8);
  border: 1px solid #333;
  border-radius: 4px;
  color: #fff;
  display: inline-block;
  font-size: smaller;
  padding: 5px; }

@-webkit-keyframes dash {
  from {
    stroke-dashoffset: 24; }
  to {
    stroke-dashoffset: 0; } }

@keyframes dash {
  from {
    stroke-dashoffset: 24; }
  to {
    stroke-dashoffset: 0; } }

.srd-default-link path {
  stroke: #f26430 ;
  fill: none;
  stroke-width: 3px;
  pointer-events: all; }

.srd-default-link--path-selected {
  /* stroke: #f26430 !important; */
  stroke-dasharray: 10,2;
  /* animation: dash 3s linear infinite;  */
}

.srd-default-link--path-executed {
  stroke:rgba(00, 00, 200, 0.8) !important;
  stroke-dasharray: 23,1;
  -webkit-animation: dash 2s linear infinite;
          animation: dash 2s linear infinite; 
}
  
.srd-default-link__label {
  pointer-events: none; }
  .srd-default-link__label > div {
    display: inline-block;
    position: absolute; }

.srd-default-link__point {
  fill: rgba(255, 255, 255, 0.5); }

.srd-default-link--point-selected {
  fill: #00c0ff; }

.diagram-layer{
  height:100%;
  /* width: 100%; */
	background-image:
    -webkit-linear-gradient(rgba(0,99,203, 0.1) 2px, transparent 2px), 
    -webkit-linear-gradient(left, rgba(0,99,203, 0.1) 2px, transparent 2px), 
    -webkit-linear-gradient(rgba(0,99,203, 0.15) 1px, transparent 1px), 
    -webkit-linear-gradient(left, rgba(0,99,203, 0.15) 1px, transparent 1px);
	background-image:
    linear-gradient(rgba(0,99,203, 0.1) 2px, transparent 2px), 
    linear-gradient(90deg, rgba(0,99,203, 0.1) 2px, transparent 2px), 
    linear-gradient(rgba(0,99,203, 0.15) 1px, transparent 1px), 
    linear-gradient(90deg, rgba(0,99,203, 0.15) 1px, transparent 1px);
	background-size: 50px 50px, 50px 50px, 10px 10px, 10px 10px;
  background-position: -2px -2px, -2px -2px, -1px -1px, -1px -1px;  
  border-color: rgba(0,99,203, 0.2);
  border-style: solid;
  border-width: 2px;
}

.diagram-canvas {
    height: 100%;
  }

@font-face {
    font-family: 'Lovelo';src: url('/Lovelo Line Light.otf');
}

.logo {
    font-family: Lovelo, Arial, sans-serif; font-size:2em;color: white;
}

.se-centered {
    display: flex;
    justify-content: center;
}

.se-section {
    padding: 10px;
}
.se-section-title {
    padding: 30px;
    font-weight: bold;
    font-size: 150%;
}
.react-monaco-editor-container {
    margin-bottom: 25px;

}
.overflow-guard {
    border-width: 1px;
    border-radius: 2px;
    border-style: solid;
    border-color: rgb(190,190,190);
    margin-bottom: 25px;

}

.testNow {
    flex-direction: row !important;
    /* background-color: aqua; */
    padding-left: 35%;
}

::-webkit-scrollbar {
    width: 5px;
    height : 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1; 
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555; 
}


