
.menuItem {
    line-height:64px;
}
.menuActions {
    display: -webkit-inline-box;
    line-height:64px;
    margin-left: 40px;
}

.se-editable-cell-dropdown {
    min-width:50%;
    max-width:100%;

}

.se-admin-header {

    padding: 0px 0px 8px 30px;
    margin-top: 10px;
    border-width: 0px 0px 3px 0px ;
    border-style: solid;
    border-color: #1890ff;
    font-weight: bold;
    font-size: 22px;

}

.se-admin-table {
    margin: 10px 60px 0px 60px;
}
.se-admin-table-container{
    border-width:0px 0px 3px 0px;
    border-style: solid;
    border-color: #1890ff;

}

.se-admin-table-row>td {
    padding: 0px 0px 4px 0px !important;
 }


 .se-admin-table-add-button {
    position: relative;
    top: -50px;
    left: 25px;
    float: left;
    width :100px;
    z-index: 10;
    background-color: transparent;
    border-width: 1px;
    border-color: transparent;
    font-size: 14pt;
    color: #1890ff;
    
}
.se-admin-table-add-button:hover {
    color: #0055a1;
    background-color : #e6e6e6; 
    border-width: 1px;
    border-style: solid;
    border-radius: 2px;
}

/**
 */
#vg-tooltip-element > table > tbody > * > td.key {
     display: none;
}

#vg-tooltip-element > table > tbody > tr:nth-child(1) > td.value {
    color: #1890ff;
    font-size: 45px;
    font-weight: bold;
    position: absolute;
    width: 100%;
    text-align: center;
    left: 0px;
    top :15%;
    
}
#vg-tooltip-element > table > tbody > tr:nth-child(2) > td.value {
    color: #0055a1;
    font-size: 16px;
    font-weight: bold;
    position: absolute;
    width: 100%;
    text-align: center;
    left: 0px;
    top :65%;
}
 #vg-tooltip-element {
    color: red;
    padding : 30px;
    /* visibility: visible !important ; */
    width: 100px;
    height: 100px !important;
    border-radius: 50px;

    box-shadow: 5px 5px 4px gray;
    transition: visibility 05s, opacity 0.5s linear;
  
  }